type GetAssetBatchIndexArgs = {
  isIncrementing: boolean;
  isLastRowInPage: boolean;
  isFirstRowInPage: boolean;
  isFirstPageInAssetBatch: boolean;
  isLastPageInAssetBatch: boolean;
  previousAssetBatchIndex: number;
};

export const getAssetBatchIndex = ({
  isIncrementing,
  isLastRowInPage,
  isFirstRowInPage,
  isFirstPageInAssetBatch,
  isLastPageInAssetBatch,
  previousAssetBatchIndex,
}: GetAssetBatchIndexArgs) => {
  if (isIncrementing) {
    if (isLastRowInPage && isLastPageInAssetBatch) {
      return previousAssetBatchIndex + 1;
    }
  } else if (isFirstRowInPage && isFirstPageInAssetBatch) {
    return previousAssetBatchIndex - 1;
  }

  return previousAssetBatchIndex;
};
