import { FeedTblRow } from "shared/types/assetExporter";

export const getAssetCount = (
  feedRows: FeedTblRow[],
  selectedAssetBatches: string[],
) => {
  if (!feedRows?.length) return 0;

  const countSelectedAssetBatches = (row: FeedTblRow) => {
    const assetBatchesForRow = row.assetBatches?.filter(batch =>
      selectedAssetBatches.includes(batch),
    );
    return assetBatchesForRow?.length || 0;
  };

  return feedRows.reduce((total, row) => {
    const selectedBatchesInRow = countSelectedAssetBatches(row);
    return total + selectedBatchesInRow;
  }, 0);
};
