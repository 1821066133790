import { useCallback } from "react";
import { useQuery } from "react-query";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { fetchFeedTblRow } from "shared/hooks/assetExporter/useFeedConfigFetch";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { errorNotification } from "shared/components/customNotification/Notification";
import { useDataList } from "shared/components/dataList";

export const usePortalFeedData = (
  currentPage: number,
  assetBatchIndex: number,
) => {
  const { feedId, selectedRows, selectedAssetBatches } = useFeedContext();
  const [dataList] = useDataList();

  const currentAssetBatch = selectedAssetBatches[assetBatchIndex];

  const fetchPage = useCallback(
    async (page: number, assetBatchId: string) => {
      try {
        if (!feedId) {
          throw new Error("Feed ID is not available");
        }

        const feedRows = await fetchFeedTblRow({
          feedId,
          page: String(page),
          filterArgs: [
            {
              column: COLUMN_ASSET_BATCHES,
              selection: [assetBatchId],
            },
          ],
        });

        const selectedFeedRows = dataList.selectAll
          ? feedRows?.rows
          : feedRows?.rows.filter(row =>
              selectedRows.includes(row.rowIdentifier),
            );

        return {
          ...feedRows,
          rows: selectedFeedRows,
        };
      } catch (error) {
        errorNotification({
          messageLabel: `An error occurred while fetching feed data`,
        });
        throw error;
      }
    },
    [feedId, selectedRows, dataList.selectAll],
  );

  const result = useQuery(
    [
      "portalFeedData",
      feedId,
      currentPage,
      currentAssetBatch,
      selectedRows.length,
    ],
    () => fetchPage(currentPage, currentAssetBatch),
    {
      enabled: !!feedId,
    },
  );

  return { result, currentAssetBatch };
};
