import styles from "./Portal.module.scss";
import { Image, Spin, Typography } from "antd";
import { MoreVert } from "@mui/icons-material";
import { FeedTblRow } from "shared/types/assetExporter";
import { Tags } from "./Tags";
import { usePortalExportContext } from "./PortalExportContext";
import { useFetchArtboards } from "shared/hooks/useFetchArtboards";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { StringParam, useQueryParams } from "use-query-params";
import { usePortalFeedData } from "./usePortalFeedData";
import { getPreviewCardData } from "./utils/getPreviewCardData";

export const PreviewCard = ({
  assetIndex,
  assetBatchIndex,
  feedData,
  isLoading,
  setIsLoadingNextImage,
  rowIndex,
  currentPage,
}: {
  assetIndex: number;
  assetBatchIndex: number;
  feedData: FeedTblRow[];
  isLoading: boolean;
  setIsLoadingNextImage: (value: boolean) => void;
  rowIndex: number;
  currentPage: number;
}) => {
  const { data: artboards, isLoading: isLoadingArtboards } =
    useFetchArtboards();
  const { assetBatchesForSelectedRows, feedId } = useFeedContext();
  const [queryParams] = useQueryParams({ feedName: StringParam });
  const { formValues } = usePortalExportContext();
  const { feedName } = queryParams;
  const { currentAssetBatch: assetBatchId } = usePortalFeedData(
    currentPage,
    assetBatchIndex,
  );
  const currentAssetBatch = assetBatchesForSelectedRows.find(
    assetBatch => assetBatch.assetBatchId === assetBatchId,
  );

  const currentRow = feedData[rowIndex];

  const { rowName, thumbnailURL, dimensions } = getPreviewCardData({
    currentAssetBatch,
    currentRow,
    artboards,
    feedId,
    feedName,
    assetIndex,
  });

  return (
    <div className={styles.previewCard}>
      <Spin
        spinning={isLoading || isLoadingArtboards}
        wrapperClassName={styles.spinWrapper}
      >
        <div className={styles.previewImageWrapper}>
          <Image
            onLoad={() => setIsLoadingNextImage(false)}
            onError={() => setIsLoadingNextImage(false)}
            preview={false}
            src={thumbnailURL}
          />
        </div>

        <div className={styles.metadataContainer}>
          <div>
            <Typography.Text className={styles.rowNameTitle}>
              {rowName}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              className={styles.dimensionsTitle}
            >
              {dimensions}
            </Typography.Text>
          </div>
          <div className={styles.fieldsContainer}>
            <Tags
              currentRow={currentRow as FeedTblRow}
              feedColumns={formValues.feedColumns}
            />
            <MoreVert className={styles.moreOutlinedIcon} />
          </div>
        </div>
      </Spin>
    </div>
  );
};
