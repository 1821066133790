import { InputNumber } from "antd";
import styles from "./CostInput.module.scss";

type CostInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};
export const CostInput = ({
  value: currentValue,
  onChange,
}: CostInputProps) => {
  const formatter = (val: string | undefined) => {
    if (!val) return "0.00";
    return Number(val).toFixed(2);
  };

  const getParsedValue = (newValue: string, oldValue: string) => {
    if (Number(newValue) === Number(oldValue)) return newValue;

    // If the new value is less than 0.01, multiply by 10 to avoid values less than 0.01
    if (Number(newValue) < 0.01) return (Number(newValue) * 10).toString();

    const [newValueIntegerPart, newValueDecimalPart] = newValue.split(".");
    // If the new value contains only one decimal place, divide by 10
    if (newValueDecimalPart?.length === 1) {
      return (Number(newValue) / 10).toString();
    }
    const formattedOldValue = oldValue?.toString() ?? "0.00";

    // If the integer part of the new value is the same as the old value, multiply by 10
    const formattedOldValueIntegerPart = formattedOldValue.split(".")[0];
    if (newValueIntegerPart === formattedOldValueIntegerPart) {
      return (Number(newValue) * 10).toString();
    }
    return newValue || "";
  };

  const parser = (val: string | undefined) => {
    if (!val) return "0.00";
    return getParsedValue(val, currentValue || "0.00");
  };

  return (
    <InputNumber
      value={currentValue}
      onChange={onChange}
      formatter={formatter}
      parser={parser}
      precision={2}
      className={styles.inputNumber}
      prefix="$"
      min="0.01"
    />
  );
};
