import { PAGE_SIZE } from "shared/hooks/assetExporter/useFeedConfigFetch";

type GetRowIndexArgs = {
  isIncrementing: boolean;
  isLastRowInPage: boolean;
  isFirstRowInPage: boolean;
  isFirstPageInAssetBatch: boolean;
  previousRowIndex: number;
  assetBatchRowCounts: number[];
  nextAssetBatchIndex: number;
};

export const getRowIndex = ({
  isIncrementing,
  isLastRowInPage,
  isFirstRowInPage,
  isFirstPageInAssetBatch,
  previousRowIndex,
  assetBatchRowCounts,
  nextAssetBatchIndex,
}: GetRowIndexArgs) => {
  if (isIncrementing) {
    if (isLastRowInPage) {
      return 0;
    }
    return previousRowIndex + 1;
  } else {
    if (isFirstRowInPage) {
      const previousPageRowCount =
        assetBatchRowCounts[nextAssetBatchIndex] % PAGE_SIZE || PAGE_SIZE;
      return isFirstPageInAssetBatch ? previousPageRowCount - 1 : PAGE_SIZE - 1;
    }
    return previousRowIndex - 1;
  }
};
