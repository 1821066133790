import { PAGE_SIZE } from "shared/hooks/assetExporter/useFeedConfigFetch";

export const getPageNumber = (
  assetIndex: number,
  assetBatchRowCounts: number[],
  assetBatchIndex: number,
) => {
  const previousBatchesAssetCount = assetBatchRowCounts
    .slice(0, assetBatchIndex)
    .reduce((acc, curr) => acc + curr, 0);
  const assetIndexInCurrentBatch =
    previousBatchesAssetCount > assetIndex
      ? assetIndex
      : assetIndex - previousBatchesAssetCount;
  if (assetIndexInCurrentBatch >= assetBatchRowCounts[assetBatchIndex]) {
    return 1;
  }
  return Math.floor(assetIndexInCurrentBatch / PAGE_SIZE) + 1;
};
