import { getNameForRow } from "screens/assetExporter/feedConfigurationV2/shared/utils";
import { FeedTblRow, TAssetBatch } from "shared/types/assetExporter";
import { getThumbnailURL } from "../../adLibrary/utils/getThumbnailURL";

export const getPreviewCardData = ({
  currentAssetBatch,
  currentRow,
  artboards,
  feedId,
  feedName,
  assetIndex,
}: {
  currentAssetBatch: TAssetBatch | undefined;
  currentRow: FeedTblRow;
  artboards: any;
  feedId: string;
  feedName: string | null | undefined;
  assetIndex: number;
}) => {
  if (!currentRow) return { rowName: "", thumbnailURL: "", dimensions: "" };
  const rowName = getNameForRow(
    currentRow,
    currentAssetBatch?.namingRulePattern ?? "",
    feedName ?? "",
    currentAssetBatch?.name ?? "",
    assetIndex,
  );

  const thumbnailURL = getThumbnailURL(
    feedId,
    currentAssetBatch?.assetBatchId ?? "",
    currentAssetBatch?.createdAt ?? 0,
    currentRow.rowIdentifier ?? "",
  );

  const currentArtboard = artboards?.result?.artboards?.find(
    (artboard: any) => artboard.name === currentAssetBatch?.artboardName,
  );

  const dimensions = currentArtboard
    ? `${currentArtboard?.width} x ${currentArtboard?.height}`
    : "";

  return {
    rowName,
    thumbnailURL,
    dimensions,
  };
};
