import { useQuery } from "react-query";
import API from "services";
import { errorNotification } from "shared/components/customNotification/Notification";
import { useDataList } from "shared/components/dataList/useDataList";
import { IFilter } from "shared/hooks/assetExporter/useFeedConfigFetch";
import { useFeedContext } from "../../shared/contexts/FeedContext";

interface FetchAssetCountArgs {
  feedId: string;
  search: string;
  filters: IFilter[];
  assetBatchIds: string[];
}

const fetchAssetCount = async ({
  feedId,
  search,
  filters,
  assetBatchIds,
}: FetchAssetCountArgs) => {
  try {
    const { result } = await API.services.assetExporter.getAssetCount({
      feedId,
      search,
      filters,
      assetBatchIds,
    });

    if (!result || !result.assetCount) {
      throw new Error("Unable to fetch asset count");
    }

    return result;
  } catch (error) {
    errorNotification({
      messageLabel: `An error occurred while fetching asset count`,
    });
    throw error;
  }
};

export const useAssetCount = () => {
  const [dataList] = useDataList();
  const {
    feedId,
    filterArgs: filters,
    reqSearchValue: search,
    selectedAssetBatches,
  } = useFeedContext();

  return useQuery(
    ["assetCount", feedId, search, filters?.length],
    () =>
      fetchAssetCount({
        feedId,
        search,
        filters: filters ?? [],
        assetBatchIds: selectedAssetBatches,
      }),
    {
      enabled: !!feedId && !!dataList.selectAll,
      cacheTime: 0,
    },
  );
};
