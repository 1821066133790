import styles from "./Portal.module.scss";
import { Typography } from "antd";
import { useState } from "react";
import { Navigation } from "../../shared/components/Preview/Navigation";
import { usePortalFeedData } from "./usePortalFeedData";
import { getAssetCount } from "./utils/getAssetCount";
import { useFeedContext } from "../../shared/contexts/FeedContext";
import { PreviewCard } from "./PreviewCard";
import { useDataList } from "shared/components/dataList/useDataList";
import { useAssetCount } from "./useAssetCount";
import { PAGE_SIZE } from "shared/hooks/assetExporter/useFeedConfigFetch";
import { getPageNumber } from "./utils/getPageNumber";
import { getRowIndex } from "./utils/getRowIndex";
import { getAssetBatchIndex } from "./utils/getAssetBatchIndex";

interface NavigationState {
  isIncrementing: boolean;
  isLastRowInPage: boolean;
  isFirstRowInPage: boolean;
  isFirstPageInAssetBatch: boolean;
  isLastPageInAssetBatch: boolean;
}

export const RightPanel = () => {
  const [assetIndex, setAssetIndex] = useState(0);
  const [rowIndex, setRowIndex] = useState(0);
  const [isLoadingNextImage, setIsLoadingNextImage] = useState(true);
  const [dataList] = useDataList();
  const { selectedAssetBatches } = useFeedContext();
  const { data: assetCountData, isLoading: isLoadingAssetCount } =
    useAssetCount();
  const [assetBatchIndex, setAssetBatchIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [assetBatchRowCounts, setAssetBatchRowCounts] = useState<number[]>([]);

  const { result } = usePortalFeedData(currentPage, assetBatchIndex);
  const { data: feedData, isLoading: isLoadingFeedData } = result;

  const getNavigationState = (nextAssetIndex: number) => ({
    isIncrementing: nextAssetIndex > assetIndex,
    isLastRowInPage: rowIndex >= (feedData?.rows?.length ?? 0) - 1,
    isFirstRowInPage: rowIndex === 0,
    isFirstPageInAssetBatch: currentPage === 1,
    isLastPageInAssetBatch:
      PAGE_SIZE * currentPage >= assetBatchRowCounts[assetBatchIndex],
  });

  const updateAssetBatchRowCounts = () => {
    const assetBatchRowCount = dataList.selectAll
      ? result.data?.filteredCount ?? 0
      : result.data?.rows?.length ?? 0;
    setAssetBatchRowCounts(prevAssetBatchRowCounts => {
      const nextAssetBatchRowCounts = [...prevAssetBatchRowCounts];
      nextAssetBatchRowCounts[assetBatchIndex] = assetBatchRowCount ?? 0;
      return nextAssetBatchRowCounts;
    });
  };

  const updateAssetBatchIndex = (navigationState: NavigationState) => {
    const nextAssetBatchIndex = getAssetBatchIndex({
      ...navigationState,
      previousAssetBatchIndex: assetBatchIndex,
    });
    setAssetBatchIndex(nextAssetBatchIndex);
    return nextAssetBatchIndex;
  };

  const updateRowIndex = (
    navigationState: NavigationState,
    nextAssetBatchIndex: number,
  ) => {
    setRowIndex(previousRowIndex =>
      getRowIndex({
        ...navigationState,
        previousRowIndex,
        assetBatchRowCounts,
        nextAssetBatchIndex,
      }),
    );
  };

  const updatePage = (nextAssetIndex: number) => {
    const nextPage = getPageNumber(
      nextAssetIndex,
      assetBatchRowCounts,
      assetBatchIndex,
    );
    setCurrentPage(nextPage);
  };

  const handleNavigate = (nextAssetIndex: number) => {
    const navigationState = getNavigationState(nextAssetIndex);

    if (!assetBatchRowCounts[assetBatchIndex]) updateAssetBatchRowCounts();

    const nextAssetBatchIndex = updateAssetBatchIndex(navigationState);
    updateRowIndex(navigationState, nextAssetBatchIndex);
    setAssetIndex(nextAssetIndex);
    updatePage(nextAssetIndex);
    setIsLoadingNextImage(true);
  };

  const assetCount = dataList.selectAll
    ? assetCountData?.assetCount ?? 0
    : getAssetCount(feedData?.rows ?? [], selectedAssetBatches);

  return (
    <div className={styles.rightPanel}>
      <div className={styles.navigationContainer}>
        <Typography.Text strong className={styles.previewTitle}>
          Preview
        </Typography.Text>
        <Navigation
          assetCount={assetCount}
          onNavigate={handleNavigate}
          disabled={isLoadingFeedData}
          isLoading={isLoadingFeedData}
        />
      </div>

      <div className={styles.rightPanelContent}>
        <PreviewCard
          assetIndex={assetIndex}
          assetBatchIndex={assetBatchIndex}
          rowIndex={rowIndex}
          currentPage={currentPage}
          feedData={feedData?.rows ?? []}
          isLoading={
            isLoadingNextImage || isLoadingAssetCount || isLoadingFeedData
          }
          setIsLoadingNextImage={setIsLoadingNextImage}
        />
      </div>
    </div>
  );
};
